<template>
    <div>
        <div class="row">
            <div class="col-md-4 pl-4">
                
            </div>
            <div class="col-md-4 pl-4 text-center">
               <h4 class="text-center text-primary">Process Payroll</h4>
            </div>
            <div class="col-md-4 text-right">
                
            </div>
            
        </div>
        
        <CCard class="p-4">
          <CRow>
            <CCard class="p-4 pl-4 pr-4 ml-2 mr-2 mt-2">
              <CRow>
                  <CCol lg="12" class="mt-2" >
                    <strong> Payroll Period Covered</strong>
                    
                  </CCol>
              </CRow>
              <hr>
               <CRow>  

                  <CCol lg="12" class="form-inline">
                    <div>
                    <h6>From</h6>
                    <div class="form-inline">
                      <Datepicker
                        input-class="form-control bg-white"
                        v-model="payroll_date_from"
                        
                      >
                      </Datepicker>
                      <i class="fa fa-calendar ml-2"></i>
                    </div>
                  </div>
                
                    <div class="ml-3">
                    <h6>To</h6>
                    <div class="form-inline">
                      <Datepicker
                        input-class="form-control bg-white"
                        v-model="payroll_date_to"
                      >
                      </Datepicker>
                      <i class="fa fa-calendar ml-2"></i>
                    </div>
                  </div>
                  </CCol>	
               </CRow>
               <CRow class="mt-2 text-right">
                <CCol class="text-left">
                  <span v-if="no_data == true" class="text-danger"><em>No data for this selected period.</em></span>
                </CCol>
                <CCol>
                  <a href="javascript:void(0)" :class="{'disabled' : !(payroll_date_from && payroll_date_to)}" 
                    class="btn btn-info" @click="generatePayroll"> 
                    <i class="fa fa-plus"></i> Generate Payroll</a>
                </CCol>
               </CRow>
            </CCard>
        </CRow>
      </CCard>
      
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from 'moment'
export default {
    name : 'step1',
    components: { Datepicker, vSelect },
    filters: {
      formatDate(val) {
        if(val) 
          return moment(val).format('MM/DD/YYYY')
        return '';
      },
    },
    
    props: {
      no_data : {
        type: Boolean,
        default: null
      }
    },
    data() {
      return {
        payroll_date_from : null,
        payroll_date_to : null,
        
      }
    },
    mounted() {},
    methods: {
      generatePayroll() {
        this.$emit('generate', {from : this.payroll_date_from, to : this.payroll_date_to})
      },
      processPayroll(q) {
        this.$emit('process', q)
      }
    }
    
    
}

</script>