<template>
	<div>
        <CCard class="p-3"> 
           <CRow>
                
                <CCol lg="4" class="pl-4" style="margin-top:-5px">
                    <h5>Payroll Period</h5>
                    <h4 class="mb-1">{{from | date_format}} - {{to | date_format}}</h4>
                </CCol>
                <CCol lg="4" class="pl-4 text-center">
                    <h4 class="text-center">Payroll Summary</h4>
                </CCol>
                <CCol lg="4" class="text-right">
                    <template v-if="$parent.isPrevBtnDisabled(status, 3)">
                        <a v-if="status=='approved'"  href="javascript:void(0)"  class="btn btn-success px-3 mr-2 btn-md" @click="paid">
                            <i class="fa fa-money"></i> PAID
                        </a>
                        <!-- <a href="javascript:void(0)" class="btn btn-outline-primary  mr-4 btn-sm  disabled" @click="generate">
                            <i class="fa fa-download"></i> Generate
                        </a> -->
                    </template>
                    <template v-else>
                        <a href="javascript:void(0)" class="btn btn-info mr-2 btn-sm" @click="prevBtn">
                            <i class="fa fa-chevron-left"></i> Previous
                        </a>
                    </template>
                    
                </CCol>
                
            </CRow>
        </CCard>
		<CCard class="p-2"> 
	        <CCardBody>
				 <CRow >
                        <CCol lg="6">
                            <h5 class="text-uppercase text-primary">
                                <strong> {{status}} </strong>
                            </h5>
                        </CCol>
                        <CCol lg="6" class="text-right">
                            <template v-if="$parent.isPrevBtnDisabled(status, 3)"> 
                            <a href="javascript:void(0)" class="btn btn-outline-success mr-2 btn-sm " @click="print" 
                             v-if="config.getPermission('payroll').print">
                            <i class="fa fa-print"></i> Print Payslip
                            </a>
                           </template>
                            <template v-if="$parent.isPrevBtnDisabled(status, 3)"> 
                            <a v-if="config.getPermission('payroll').download" 
                                href="javascript:void(0)" class="btn btn-outline-primary btn-sm " @click="generate" >
                            <i class="fa fa-download"></i> Generate Payroll
                           </a>
                          </template>
                        </CCol>
                    </CRow>
                    <hr >
                <CRow> 
                    <CCol lg="12">
				        <h5 style="color: #1352a1;">Employee Payments</h5>
			        </CCol>
						<CCol lg="12">
                                
                            <div class="table-responsive-sm table-responsive-md">
                                <table class="table table-outlined table-sm text-left" ref="table">
                                    <thead class="bg-light " style="font-weight:bold">
                                        <tr>
                                            <td>EMPLOYEE</td>
                                            <td>NO. OF TRIPS </td>
                                            <td>EARNINGS</td>
                                            <td>DEDUCTION</td>
                                            <td>NET PAY</td>
                                            <td>PAYMENT</td>
                                            <td v-if="status == 'approved' || status == 'paid'"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(payroll, index) in payrollSummary" :key="index">
                                        
                                            <td ><p class="mb-0">{{payroll.personnel_name}}</p></td>
                                            <td >{{payroll.basic_salary_qty}}</td>
                                            <td >{{payroll.total_earning | number_format(null,2)}}</td>
                                            <td >{{payroll.total_deduction | number_format(null,2)}}</td>
                                            <td >{{payroll.net_pay | number_format(null,2)}}</td>
                                            <td>{{ payment_method_value(payroll.payment_method) }}</td> 
                                            <td v-if="status == 'approved' || status == 'paid'">
                                                <a href="javascript:void(0)" class="btn btn-sm btn-info" @click="viewDetail(payroll)">
                                                    <i class="fa fa-eye"></i> View
                                                </a>
                                            </td>
                                        </tr>

                                        <tr >
                                           
                                            <td style="font-weight:600; font-size: 20px!important;">TOTAL</td>
                                            <td style="font-weight:600; font-size: 20px!important;" >{{payrollSummary | sumAll('no_of_trips')}}</td>
                                            <td style="font-weight:600; font-size: 20px!important;" >{{payrollSummary | sumAll('earning') | number_format('currency')}}</td>
                                            <td style="font-weight:600; font-size: 20px!important;" >{{payrollSummary | sumAll('deduction') | number_format('currency')}}</td>
                                            <td style="font-weight:600; font-size: 20px!important;" >{{payrollSummary | sumAll('netpay') | number_format('currency')}}</td>
                                            <td ></td>
                                            
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>  
                                
    
                
                        </CCol>
						
					</CRow> 
                    <CRow v-if="!isApproved">
                        <CCol lg="12">
                            <a href="javascript:void(0)" class="btn btn-info" @click="submit('draft')">
                                <i class="fa fa-bookmark"></i> Save as draft</a>
                            <a href="javascript:void(0)" class="btn btn-success px-3 float-right" @click="submit('approved')">
                                <i class="fa fa-check"></i> Submit</a>
                        </CCol>
                    </CRow>
				
	        </CCardBody> 
			  
        </CCard>  
		
<Print :details="dataParams" :dataList="this.payrollSummary" />
	</div>
</template>
<script> 

    import config from '../../../config.js';
    import axios from '../../../axios';
    import Swal from "sweetalert2";
    import Datepicker from 'vuejs-datepicker';
    import vSelect from 'vue-select'
    import '../../../style.css';
    import moment from 'moment';
    import Print from '../print_all_personnel';
    
    
    export default {        
        name : 'step3',
        props: ['from','to','payroll','status'],
        beforeCreate() {
            console.log('create')
        },
        mounted(){  
            console.log('asd')            
            // this.getData(); 				
        },
        filters: {
            sumAll(val, type) {
                console.log(val, type)
                let sum = 0;
                val.forEach(element => {
                    switch (type) {
                        case 'no_of_trips':
                            sum+= parseFloat(element.basic_salary_qty);
                            break;
                        case 'earning':
                            sum+= parseFloat(element.total_earning);
                            break;
                        case 'deduction':
                            sum+= parseFloat(element.total_deduction);
                            break;
                        case 'netpay':
                            sum+= parseFloat(element.net_pay);
                            break;
                    }
                });

                return sum;
                
            }
        },
        data(){
            return{
                displayedBooking: [],
                currentPage: 1,
                dataParams: {
                
                    remarks: "",				
                },			
                dataList: [ ],
                isLoading: false,
                config,
                moment,
                limit: 20
            }
        },
        computed : {
            payment_method_value() {
                return val => {
                    if(val == "0" || !val)
                        return '';
                    else
                        return val
                }
                
            },
            isApproved() {
                return this.status == 'approved' || this.status == 'paid' ;
            },
            payrollSummary() {
                let payroll = (this.status) ? {...this.payroll} : {...this.$parent.$refs.payroll_info.form};
                let data = [];
                let index = 0;
                for (const key in payroll) {
                    const element = payroll[key];
                    data.push(element);
                    data[index]['total_earning'] = this.computeTotalEarnings(element);
                    data[index]['total_deduction'] = this.computeTotalDeduction(element);
                    data[index]['net_pay'] = data[index]['total_earning'] - data[index]['total_deduction'];
                    index++;
                }

                return data;
            }
        },
        components: { Datepicker, vSelect, Print},
        methods: {  
            generate() {
                console.log(this.payroll)
                let payroll_id = (this.payroll.length) ? this.payroll[0]['payroll_id'] : null;
                console.log(payroll_id)
                if(payroll_id) {
                    this.$showLoading(true)
                    axios.get(`${config.api_path}/payroll/download-summary`,{
                        params : {
                            payroll_id
                        }
                    }).then(response => {
                        this.$showLoading(false)
                        console.log(response)                        
                        window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					
                        setTimeout(() => {
                            axios.get(`${config.api_path}/remove-file`,{
                                params : {
                                    filename : response.data.file
                                }
                            })	
                        }, 2000);
                    }).catch(err => {
                        this.$showLoading(false)
                        console.error(err)
                    })
                }
            },
            computeTotalDeduction(emp) {
                return this.$parent.computeTotalDeduction(emp);
            },
            computeTotalEarnings(emp) {
                return this.$parent.computeTotalEarnings(emp)
            },
            prevBtn() {
                this.$emit('prev')
            },
            submit(status) {
                if (status=="approved")
                Swal.fire({
                    icon: "warning",
                    title: "Are you sure you want to submit this payroll?",
                    text: "You can't edit this payroll once submitted.",
                    reverseButtons: true,
                    showCancelButton: true,
                    confirmButtonText: `Submit`,
                }).then((result) => {
                    if (result.isConfirmed) {   
                        this.$emit('submitpayroll',status)                 
                  }
                });
                else 
                this.$emit('submitpayroll',status)        
              
            },
            updatePage(page) {
                let t = [...this.displayedBookingHolder];
                let index = (page - 1) * this.limit;
                this.displayedBooking = t.slice(index, index + this.limit);
            },
            paid(){
                Swal.fire({
                    icon: "warning",
                    title: "Please make sure the DATA is correct.",
                    text: "Clicking PAID button will update the status to PAID.",
                    reverseButtons: true,
                    showCancelButton: true,
                    confirmButtonText: `Submit`,
                }).then((result) => {
                    if (result.isConfirmed) {                    
                    axios.post(config.api_path + `/payroll/update-payroll-paid`, {
                        payroll_id: this.$route.params.id,
                        payroll_status: 'paid'
                    })
                    .then(function (response) {
                        console.log(response);
                        Swal.fire({
                            title: "Success!",
                            text: "Payroll  successfully paid",
                            icon: "success",
                        });                        
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                    this.$router.push('/main/payroll');
                  }
                });
            },
            viewDetail(payroll) {
                console.log(payroll)
                 window.open(`#/main/view_payroll_personnel/${payroll.payroll_id}/${payroll.personnel_id}`, '_blank');
            //     this.$showLoading(true)
            //     axios.get(`${config.api_path}/payroll/personnel/${payroll.payroll_id}/${payroll.personnel_id}`).then(res => {
            //         const {data, status} = res.data;
            //         console.log(data)
            //         this.$showLoading(false)
            //     })
            //     .catch(err => {
            //         this.$showLoading(false)
            //     })
            },
            
             print(){
    
                var mywindow = window.open('', 'PRINT');
    
                mywindow.document.write('<html><head>');
                mywindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css" integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We" crossorigin="anonymous">')
                mywindow.document.write('<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">')
                mywindow.document.write('</head><body >'); 
                mywindow.document.write(document.getElementById('month_print').innerHTML);
                mywindow.document.write('</body></html>');
    
                mywindow.document.close(); // necessary for IE >= 10
                mywindow.focus(); // necessary for IE >= 10*/
    
                mywindow.print();
                mywindow.close();
            },
    
           
    
           
            
         }
        
    }
    </script>

